// LOADMORE
(() => {
  const findContext = (elem) => {
    if (elem.classList && elem.classList.contains('tc_load-more')) {
      return elem;
    }
    if (elem.parentNode) {
      return findContext(elem.parentNode);
    }
    return null;
  };

  const evaluateButton = (btn) => {
    const url = btn.dataset.url;
    const context = findContext(btn);
    if (!context) {
      return;
    }

    btn.classList.add('js_load-more__bound');
    btn.addEventListener('click', () => {
      let xhr;

      if (!btn.classList.contains('tc_button--loading')) {
        btn.classList.add('tc_button--loading');

        xhr = new XMLHttpRequest();
        xhr.onload = function _load() {
          const parentNode = context.parentNode;
          if (this.status === 200) {
            context.outerHTML = this.response;
            identifyButtons(parentNode);
          }
        };
        xhr.onloadend = () => {
          btn.classList.remove('tc_button--loading');
        };
        xhr.open('GET', url);
        xhr.responseType = 'text';
        xhr.send();
      }
    });
  };

  function identifyButtons(context) {
    const buttons = context.querySelectorAll(
      '.tc_load-more__trigger[data-url]',
    );
    let i;
    for (i = 0; i < buttons.length; i += 1) {
      if (
        !(
          buttons[i].classList &&
          buttons[i].classList.contains('js_load-more__bound')
        )
      ) {
        evaluateButton(buttons[i]);
      }
    }
  }

  function domready() {
    identifyButtons(document);
  }
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', domready);
  } else {
    domready();
  }
})();
